.modal{
    display:flex;
    width: 100%;
    height: 100vh;
    top:0;
   justify-content: center;
   align-items: center;
   padding: 20px;
   position: fixed;
   background-color: rgba(0,0,0,0.5);
   z-index: 100;
   &__container{
    background-color: white;
    border-radius: 10px;
    padding: 30px 20px;
    max-width: 600px;
 
    text-align: center;
   }
   &__title{
   font-size: 30px;
   margin-top: 20px;
   margin-bottom: 50px;
   }
   &__text{
    font-size: 20px;
    margin-bottom: 20px;
   }
   &__button{
    background-color: $color_blue;
    border-radius: 5px;
    color: $primary_color;
    font-size: 18px;
    padding: 5px 10px;
    text-decoration: none;
    display: inline-block;
    cursor:pointer;
    transition: 0.3s ease all;
   }
   &__button:hover{
    color:$color_blue;
    background-color: $primary_color;
   }
   .modal__icon{
    color:$color_blue;
    font-size: 30px;
    display: block;
    text-align: right;
    cursor: pointer;
   }
}