.header{
    height:100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0px;
    &__hello{
        color:$color_blue;
        text-align: center;
        font-weight: 400;
        letter-spacing: 1.2px;
    }
    &__name{
        color:white;
        font-size: 3rem;
        font-weight: 400;
        text-align: center;
        letter-spacing: 1.2px;
       
    }
    &__position{
        color:$color_blue;
        text-align: center;
        font-weight: 400;
        letter-spacing: 1.2px;
    }
    &__content{
        // border-radius: 10px;
        width: 300px;
        // background-color:rgba(201,211,212,0.3);
        background-color: white;
        padding:10px;
        display: flex;
        margin: 70px auto 50px auto;
    }
   
    &__containerimg{
        // border-radius: 10px;
        width:800px;
        margin: auto;
    }
    &__profileimg{
        box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        // border-radius: 10px;
        width: 100%;
        height: 370px;
        object-fit: cover;
        object-position: 10%;
    }
    &__about{
        padding:20px;
    }
    &__box{
        margin-top: 30px;
    }
    &__cv{
        border:solid 2px $color_blue;
        background-color:$color_blue;
        padding: 10px 15px;
        border-radius: 10px;
        text-align: center;
        color:$primary_color;
        font-size: 18px;
        margin-right: 10px;
        width: 150px;
        transition: 0.3s ease all;
    }
    &__cv:hover{
        background-color: transparent;
        border:solid 2px $color_blue;
        color:$color_blue;
        cursor:pointer;
    }
    &__contact{
      
        background:none;
        border:solid 2px $color_blue;
        padding: 10px 15px;
        border-radius: 10px;
        text-align: center;
        color:$color_blue;
        font-size: 18px;
        width: 150px;
        transition: 0.3s ease all;
    }
    &__contact:hover{
        background:$color_blue;
        border:solid 2px $primary_color;
        color:$primary_color;
        cursor:pointer;
    }
}
@media (max-width:1280px){
    .header{
        padding: 20px 0px;
        &__box{
            margin-top: 10px;
        }
        &__content{
            margin:20px auto;
        }
        &__name{
            font-size: 2.2rem;
        }
        &__cv{
            font-size: 16px;
            padding: 8px 15px;
        }
        &__contact{
            font-size: 16px;
            padding: 8px 15px;
        }
    }
}