
.open-menu {
    width: 3rem;
    height: 3rem;
    background-color: $color_blue;
    border-radius: 100%;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    transition: all ease-in 0.2s;
    cursor: pointer;
    animation: mover ease-in-out 2s 3 alternate;
    transition: ease-out 0.5s;
    z-index: 10;
    position: fixed; }

  @keyframes mover {
    0% {
      transform: translateY(-20px); }
    100% {
      transform: translateY(0px); } }
  .open-menu::before,
  .open-menu::after {
    width: 40%;
    height: 4%;
    content: " ";
    background-color: #ffffff;
    top: 49%;
    left: 30%;
    position: absolute; }
  
  .open-menu::after {
    transform: rotate(90deg); }
  
  .pulsed {
    transform: rotate(45deg); }
  
  .menu {
    width: 16rem;
    height: 16rem;
    background-color: white;
    border-radius: 100%;
    position: fixed !important;
    bottom: -5rem;
    right: -5rem;
    color: #21243d;
    transform: scale(0) rotate(-180deg);
    transition: all ease-in 0.2s;
    z-index: 1; }
    .menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 1.5rem; }
      .menu ul li {
        color: $primary_color;
        position: absolute;
        width: 2rem;
        top: 2rem;
        left: 45%;
        transform-origin: center 6rem;
        text-align: center;
        cursor: pointer;
        /* transform:rotate(45deg); */ }
      .menu ul li:nth-child(2) {
        transform: rotate(-45deg); }
      .menu ul li:nth-child(2) span {
        color: $primary_color;
        transform: rotate(45deg); }
      .menu ul li:nth-child(3) {
        transform: rotate(-90deg); }
      .menu ul li:nth-child(3) span {
        color: $primary_color;
        transform: rotate(90deg); }
      .menu ul li span:hover {
        color: $color_blue;
        cursor: pointer; }
      .menu ul li a {
        color: $primary_color; }
  
  .menufull {
    z-index: 9;
    transform: scale(1) rotate(0deg); }
  @media (min-width:1200px){
    .menu,.open-menu{
      display: none;
    }
  }