.about{
    background-color:rgba(201,211,212, 0.3);
    width: 100%;
    height: 90vh;
    display: flex;
    &__boximage{
        padding: 20px;
    }
&__image{
    width: 100%;
}
&__content{
    padding: 20px;
}
}