.navbar {
  background-color: rgba(255, 255, 255, 0.3);
  // background-color: #3AB4F2;
  border-radius: 50px;
  width: 50px;
  height: 200px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  bottom: 10px;
 right:10px;
z-index:100!important;
  top:50%;
  transform:translateY(-50%);
  &__icon{
    color:$color_blue;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s ease all;
  }
  &__icon--active{
    background-color:$primary_color;
    font-size: 2rem!important;
    border-radius: 50px;
    padding: 5px 8px;
    cursor: pointer;
    transition: 0.3s ease all;
  
  }
 
}

// .active:hover{
//   cursor: pointer;
//   background-color: $primary_color;
//   border-radius: 50px;
//   padding: 5px 8px;
// }
@media (max-width:1199px){
  .navbar{
    display: none;
  }
  // .navbar {
  //   transform: translate(-20%, -130%);
  //   bottom: 10px;
  //   &__icon{
  //     font-size: 1.3rem;
  //   }
  // }
  // .active{
  //   background-color:$primary_color;
  //   border-radius: 50px;
  //   padding: px 8px;
  //   transition: 0.3s ease all;
  
  // }
}