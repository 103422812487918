@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
*{
    box-sizing: border-box;
    margin:0;
    padding:0;
}
$primary_color:#21243D;
$secondary_color:#108B72;
$color_gray:#C9D3D4;
$color_blue:#3AB4F2;
body{
    font-family: 'Open Sans', sans-serif;
    font-size:16px;
    // background: rgb(33,36,61);
    background-color: #21243D;
}
.container{
    width: 100%;
    max-width: 1200px;
    margin:auto;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.cards{
    width: 100%;
    display:grid;
    gap:20px;
    grid-template-columns: repeat(3, 1fr);

}
.card{
    border:solid 1px $color_blue;
    height: 350px;
    padding: 10px;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    position: relative;
    transition: 0.3s ease all;

    
    &__image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: 0, 0;
        transition: transform 1s, visibility 1s;
    }
    &__box{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 10px;
      
    }

    &__btn{
        background-color: $color_blue;
        border:none;
        color:$primary_color;
        border-radius: 5px;
        font-size: 17px;
        letter-spacing: 1.1px;
        width: 200px;
        padding: 10px 5px;
        cursor:pointer;
        transition: 0.3s ease all;
        position: relative;
        overflow: hidden;
    }
    &__btn:hover{
        background-color: $color_blue;
        color:$primary_color;
    }
    &__btn::after{   
        content: "";
      background-color: #21243D;
        width: 100%;
        z-index: 1;
        position: absolute;
        top:0px;
       left:-130%;
        height: 40px;
        transition: .4s ease-in-out all;
        }
        &__btn:hover::after{
            border-radius: 5px;
            color:white;
            left:0;
        }
        &__btn span{
            z-index: 2;
            position: relative;
            cursor: pointer;
            transition: .3s ease all;
        }
        &__btn:hover{
            color:white;
        }
}
.card:hover{
   box-shadow: 0px 0px 3px 2px rgba(255,255,255, 1);
    border:solid 1px white;
}

.title{
    text-align: center;
    color:$color_blue;
    font-size: 2.5rem;
    font-weight: 400;
    margin: 30px;
}
.arrow_left
{
    color: $color_blue;
    display: inline-block;
    font-size: 35px;
    margin: 10px none;
    cursor: pointer;
}
.d-none{
    display: none;
}
.d-block{
    display:block;
}
.d-flex{
    display: flex;
    flex-direction: column;
}
.msgError{
    color:$color_blue;
    display: block;
    margin:-5px 0px 5px 0px;
}
.msgSend{
    color:$color_blue;
    display: block;
    margin:5px 0px 5px 0px;
}
@media (max-width:1000px){
    .container{
        height: auto;
        padding: 20px;
    }
    .cards{
        grid-template-columns:repeat(2, 1fr);
    }
}
@media (max-width:630px){
    .container{
        height: auto;
        padding: 0px 40px 40px 40px;
    }
    .cards{
        grid-template-columns:1fr;
    }
    .title{
        font-size: 2rem;
    }
}