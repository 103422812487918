.projects{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  padding: 30px;
    &__title1{
        color:white;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 5px;
    
       }
   &__title2{
    color:$color_blue;
    text-align: center;
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 20px;
   }

    &__container{      
        display: grid;
        max-width: 1400px;
        margin: 0 auto;
       width: 100%;
        gap:20px;
        grid-template-columns: repeat(4, 1fr);
        padding: 20px;

    }

    &__card{       
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        height: 350px;
        transition: 0.3s ease all;
        position:relative;
        overflow: hidden;
        border-radius: 5px;
    }
    &__card:hover img{
        background-color: $color_gray;
        transition: ease-in 0.5s;
    transform: scale(1.3);
    }
    &__titlecard{
        text-align: center;
        color:white;
        font-weight: 500;
        margin-bottom: 20px;
    }
    &__image{
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        transform-origin: 0, 0;
        transition: transform 1s, visibility 1s;
    }
    &__content{
        background-image:linear-gradient(65deg, rgba(33,36,61,0.5) 0%, rgba(58,180,242,0.5) 35%, rgba(201,211,212,0.5) 100%); 
       position: absolute;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       top:0;
       left: 0;
       width: 100%;
       height: 100%;
       transition: 0.3s ease all;
    }
    &__content:hover{
        background-image:linear-gradient(65deg, rgba(33,36,61,0.3) 0%, rgba(58,180,242,0.3) 35%, rgba(201,211,212,0.3) 100%);  
    }
    &__button{       
        background-color: $primary_color;
        display: block;
        padding: 10px 10px;
        color: white;
        border: none;
        border-radius:5px;
        font-size: 15px;
        transition:0.5s ease all;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        transition: .3s ease all;
    }
    &__button:hover{
        color:$primary_color;
      }
    &__button span{
        z-index: 2;
        position: relative;
        cursor: pointer;
        transition: .3s ease all;
    }
    &__button span:hover{
        color:$primary_color;
    }
    
    &__button::after{   
        content: "";
      
        width: 100%;
        z-index: 1;
        position: absolute;
        top:0px;
       left:-130%;
        border-bottom:40px solid white;
        border-right: 40px solid transparent;
        transition: .4s ease-in-out all;
        }
        &__button:hover::after{
            color:$primary_color;
            left:0;
        }
   
    &__hr{
        color: $color_blue;
        background-color: $primary_color;
        height: 5px;
        margin: 15px 0px 10px 0px;
    }
}
@media (max-width:1280px){
    .projects{
        &__container{
            max-width: 1100px;
        }
    }
}
@media (max-width:1100px){
    .projects{
        height: auto;
        &__container{
           grid-template-columns: repeat(2, 1fr)
        }
    }
}
@media (max-width:560px){
    .projects{
        height: auto;
        &__container{
           grid-template-columns: 1fr;
        }
    }
}