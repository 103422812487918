.contact{
    width:100%;
    max-width: 1200px;
    margin: 50px  auto;
    height:100vh;
    display: flex;
    flex-direction: column;
 align-items: center;
 &__title1{
    color:white;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
 }
 &__title2{
    color:$color_blue;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 15px;
 }
 &__container{
   max-width: 600px;
   width:100%;
   display: grid;
   grid-template-columns:1fr;
   gap:20px;
 }
 &__form{
    display: flex;
    flex-direction: column;
    width: 100%!important;
    margin: auto;
 }
 &__input{
    background-color: transparent;
    width: 100%!important;
    display: block;
    color:white;
    margin:0px auto 10px auto;
    border:solid 2px $color_blue;
    padding: 14px 10px;
    border-radius: 15px;
    font-size: 16px;
    outline: 0px;
    transition: 0.3s ease all;
 }
 &__input:focus{
   border:solid 2px white;
 }
 &__textarea{
    background-color: transparent;
    width: 100%!important;
    display: block;
    color:white;
    margin:0px auto 10px auto;
    border:solid 2px $color_blue;
    padding: 14px 10px;
    border-radius: 15px;
    font-size: 16px!important;
    outline: 0;
    transition: 0.3s ease all;
 }
 &__textarea:focus{
   border:solid 2px white;
}
 
 &__button{
    background-color: $color_blue;
    color:$primary_color;
    padding: 10px 15px;
    border:none;
    width: 50%;
    border-radius: 15px;
    display: block;
    margin: 10px 0;
    font-size: 16px;
    transition: 0.3s ease all;
    cursor: pointer;
 }
 &__button:hover{
    cursor:pointer;
    background-color: $color_gray;
    color:$primary_color;
 }
 ::placeholder {
   color: white;
 }
}
@media (max-width:920px){
   .contact{
      margin: 20px auto;
      &__container{
         padding: 20px;
      }
      &__title1{
         margin-top:0;
      }
      &__title2{
         font-size: 40px;
      }
   }
   

}